<template>
  <v-row class="match-height">
    <v-col
      cols="12"
      md="6"
    >
      <v-card>
        <v-card-title>Create a New Project</v-card-title>
        <v-card-text>
          <project-edit-details></project-edit-details>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import ProjectEditDetails from '@/components/project/ProjectEditDetails.vue'

export default {
  components: {
    ProjectEditDetails,
  },
}

</script>
