<template>
  <v-form
    ref="projform"
    v-model="valid"
    lazy-validation
  >
    <v-row>
      <v-col
        cols="12"
        md="3"
      >
        <label for="title">Title</label>
      </v-col>

      <v-col
        cols="12"
        md="9"
      >
        <v-text-field
          id="title"
          v-model="title"
          outlined
          dense
          placeholder="Name of project"
          hide-details
          :rules="[v => !!v || 'Name is required']"
        ></v-text-field>
      </v-col>

      <v-col
        cols="12"
        md="3"
      >
        <label for="description">Description</label>
      </v-col>

      <v-col
        cols="12"
        md="9"
      >
        <v-textarea
          id="description"
          v-model="description"
          outlined
          dense
          placeholder="What is your project? What are you hoping to achieve?"
          hide-details
          :rules="[v => !!v || 'Description is required']"
        ></v-textarea>
      </v-col>

      <v-col
        cols="12"
        md="3"
      >
        <label for="motivation">Motivation</label>
      </v-col>

      <v-col
        cols="12"
        md="9"
      >
        <v-textarea
          id="motivation"
          v-model="motivation"
          outlined
          dense
          placeholder="Motivation is important. Tell us a little about why are you doing this"
          hide-details
          :rules="[v => !!v || 'Motivation is important']"
        ></v-textarea>
      </v-col>

      <v-col
        cols="12"
        md="3"
      >
        <label for="categories">Categories</label>
      </v-col>

      <v-col
        cols="12"
        md="9"
      >
        <v-combobox
          v-model="categories"
          :items="availableCategories"
          multiple
          chips
          hide-details
          outlined
        ></v-combobox>
      </v-col>

      <v-col
        offset-md="3"
        cols="12"
      >
      </v-col>

      <v-col
        offset-md="3"
        cols="12"
      >
        <v-btn
          color="primary"
          @click="save"
        >
          Submit
        </v-btn>
        <v-btn
          type="reset"
          class="mx-2"
          outlined
          @click="reset"
        >
          Reset
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { computed, ref } from '@vue/composition-api'

export default {
  setup(props, context) {
    const store = context.root.$store
    const isProcessing = computed(() => store.state.project.isProcessing)

    const title = ref('')
    const description = ref('')
    const motivation = ref()

    const categories = ref([])
    const availableCategories = [
      {
        text: 'Programming',
        value: 1,
      },
      {
        text: 'Design',
        value: 2,
      },
      {
        text: 'Vue',
        value: 3,
      },
    ]

    const getSelectedCategories = function getSelectedCategories() {
      const choices = []
      categories.value.forEach(category => choices.push(category.value))

      return choices
    }

    const valid = ref(true)
    const validate = function validata() {
      return context.refs.projform.validate()
    }

    const router = context.root.$router
    const route = context.root.$route
    const viewDetails = function viewDetails(id) {
      router.push({
        name: 'projects-view',
        query: {
          ...route.query,
        },
        params: {
          id,
        },
      })
    }

    const save = function save() {
      const isValid = validate()
      if (isValid) {
        const newProject = {
          title: title.value,
          description: description.value,
          motivation: motivation.value,
          categories: getSelectedCategories(),
        }
        store.dispatch('project/add', newProject).then(() => {
          const newID = store.state.project.current.id
          viewDetails(newID)
        })
      }
    }

    const reset = function reset() {
      context.refs.projform.reset()
    }

    return {
      title,
      description,
      motivation,
      categories,
      availableCategories,
      save,
      valid,
      reset,
      isProcessing,
    }
  },
}
</script>
